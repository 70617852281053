import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST,
  API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE,
  API_ENDPOINT_GST_CREDENTIAL_UPDATE,
} from "../../credentials.constants";
import Loader from "app/shared/Loader";
import { PlusOutlined, ExportOutlined } from "@ant-design/icons";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Empty, Modal, Spin, Tabs, Tag, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import BulkCreateGSTCreds from "./components/BulkGSTCredential";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import Typography from "app/shared/Typography";
import "../../Credentials.scss";
import PageHeader from "app/shared/PageHeader";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import TableViewSelector from "app/shared/TableViewSelector";
const GSTCredentials = forwardRef(function (props: any, ref: any) {
  const [tableData, setTableData] = useState([]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const gridRef = useRef<any>(null);

  const initialColDefs = [
    {
      field: "name",
      headerName: "Workspace",
      enableRowGroup: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      field: "pan",
      headerName: "PAN",
      enableRowGroup: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      field: "gstin",
      headerName: "GSTIN",
      enableRowGroup: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      field: "username",
      headerName: "Username",
      enableRowGroup: true,
      minWidth: 200,
      editable: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "password",
      headerName: "Password",
      enableRowGroup: true,
      minWidth: 200,
      editable: true,
    },

    {
      field: "status",
      headerName: "Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "CUSTOM_RENDER",
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          return params.status === "ACTIVE" ? (
            <Tag color="green" icon={<CheckCircleFilled />}>
              Active
            </Tag>
          ) : params.status === "INVALID" ? (
            <Tag color="red" icon={<CloseCircleFilled />}>
              Invalid
            </Tag>
          ) : (
            <Tag
              color="orange"
              icon={
                <LoadingOutlined
                  style={{
                    fontSize: 12,
                    marginRight: 6,
                    color: "rgb(143 121 24)",
                  }}
                  spin
                />
              }
            >
              Pending
            </Tag>
          );
        },

        valueGetterData: (params: any) => {
          return params.status;
        },
      },
    },

    // {
    //   field: "sync",
    //   headerName: "Job Sync",
    //   enableRowGroup: true,
    //   minWidth: 200,

    //   cellRenderer: (params: any) => {
    //     return params?.data?.status === "PENDING" ||
    //       params?.data?.status === "INVALID" ? null : (
    //       <div
    //         style={{ display: "flex", alignItems: "center", height: "100%" }}
    //       >
    //         <Tag
    //           color="blue"
    //           style={{ cursor: "pointer" }}
    //           // onClick={() => handleJobSync(params?.data)}
    //         >
    //           Sync Now
    //         </Tag>
    //       </div>
    //     );
    //   },
    // },

    {
      field: "action",
      headerName: "Action",
      enableRowGroup: true,
      editRow: true,
      focusKey: "username",
      cellRenderer: AgTableEditButton,
      cellRendererParams: {
        onClick: (params: any) => console.log(params), // Define your edit logic here
      },
    },
  ];
  useEffect(() => {
    setColumnDefs(initialColDefs);
  }, []);
  useEffect(() => {
    fetchGSTCredentials();
  }, [userDetails.currentWorkspace]);
  const fetchGSTCredentials = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST);
    if (response.status) {
      setTableData(response.data?.credentials);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  React.useImperativeHandle(ref, () => ({
    handleExportTableData,
  }));

  const updateGSTCredential = async (rowPayload: any) => {
    const response = await apiPut(API_ENDPOINT_GST_CREDENTIAL_UPDATE, {
      credentials: rowPayload,
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const createGSTCredential = async (rowPayload: any) => {
    const response = await apiPost(API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE, {
      credentials: [rowPayload],
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const handleRowEdit = (rowData: any) => {
    if (rowData.id) {
      updateGSTCredential(rowData);
    } else {
      createGSTCredential(rowData);
    }
  };

  const handleExportTableData = () => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  const handleApplyTableView = (viewData: any) => {
    if (viewData === "DEFAULT" || viewData === null) {
      setColumnDefs(initialColDefs);
      return;
    }
    setColumnDefs(viewData.columns);
    messageApi.success({
      type: "success",
      content: "View Applied",
    });
  };

  const handleSearchWorkspace = (query: string) => {
    console.log("values is:L", query);
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  return (
    <div className="ScreenContainer">
      {props.componentType ? null : (
        <PageHeader
          title="GST Credentials"
          rightActions={
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 12 }}>
                <TableViewSelector
                  defaultColumns={columnDefs}
                  onViewSelect={handleApplyTableView}
                  id="GST_CRED_VIEW"
                />
              </div>

              <Button
                type="primary"
                size="small"
                icon={<ExportOutlined />}
                onClick={handleExportTableData}
                style={{ marginRight: 12 }}
              >
                Export
              </Button>
              <Button
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => setShowModal(true)}
              >
                Add Bulk Credentials
              </Button>
            </div>
          }
        />
      )}
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={columnDefs}
            groupPanel
            onRowSaved={handleRowEdit}
            ref={gridRef}
            footer={false}
            autoResize
            showStatusBar
          />
        )}
      </div>

      <Modal
        title="Create Bulk Credentials"
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        <div style={{ marginTop: 24 }}>
          <BulkCreateGSTCreds onFinish={() => setShowModal(false)} />
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
});

export default GSTCredentials;

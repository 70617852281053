import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import Loader from "app/shared/Loader";
import { Empty, Tabs } from "antd";
import Typography from "app/shared/Typography";
import EmptyState from "app/shared/EmptyState";
import { useNavigate } from "react-router-dom";
import { getSubdomain } from "app/utils/generic.helper";
export default function Dashboard(props: any) {
  const [activeKey, setActiveKey] = useState("1");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    if (!userDetails.is_admin) navigate("/flights");
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [userDetails]);
  const domain = getSubdomain();

  const mainDashboard =
    domain === "mmt"
      ? "https://analytics.zoho.in/open-view/103074000029023867/f37053b66a4e652e63285be2992b8bb9"
      : "https://analytics.zoho.in/open-view/103074000029079389/e61c0cc77b762e70705fbabbe2b24c65";
  const errorDashboard =
    domain === "mmt"
      ? "https://analytics.zoho.in/open-view/103074000028957085/4958d45a5c947c04a33134eb9b08665d"
      : "https://analytics.zoho.in/open-view/103074000028990582/1417ab2b1c1b7ec53f62d2b6723c70e3";

  const hotelDashboard =
    domain === "mmt"
      ? "https://analytics.zoho.in/open-view/103074000030162275/a774b1d8f8e20e02d236feac7488ca89"
      : "https://analytics.zoho.in/open-view/103074000030162275/a774b1d8f8e20e02d236feac7488ca89";

  const HotelDashboard2 =
    domain === "mmt"
      ? "https://analytics.zoho.in/open-view/103074000030587436/e6b519445b0bb98b29c86ec6cada288b"
      : "https://analytics.zoho.in/open-view/103074000030587436/e6b519445b0bb98b29c86ec6cada288b";
  return isLoading ? (
    <Loader />
  ) : (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <Tabs
              items={[
                {
                  key: "1",
                  label: "Dashboard - Airline",
                },
                {
                  key: "2",
                  label: "Dashboard With Error - Airline",
                },
                ...(domain === "mmt"
                  ? [
                      { key: "3", label: "Dashboard - Hotel" },
                      { key: "4", label: "Dashboard - Hotel New" },
                    ]
                  : []),
              ]}
              tabBarStyle={{
                borderBottom: "none", // Remove the underline border
                margin: 0,
              }}
              activeKey={activeKey}
              onChange={(tabItem: string) => setActiveKey(tabItem)}
            />
          }
          // leftActions={
          //   <CustomReconTabs
          //     activeKey={activeKey}
          //     title="Report"
          //     onTabChange={(tabKey: any) => setActiveKey(tabKey)}
          //     onTabModify={(tabs: any) =>
          //       setTabView({ ...tabViews, dashboard: tabs })
          //     }
          //     hideAddTab
          //     hideRemove
          //     defaultTabs={getTabView()}
          //     width={200}
          //   />
          // }
        />

        <div className="TableContainer">
          <iframe
            height="100%"
            style={{ width: "100%" }}
            scrolling="no"
            title="Iframe"
            src={
              activeKey === "1"
                ? mainDashboard
                : activeKey === "2"
                ? errorDashboard
                : activeKey === "3"
                ? hotelDashboard
                : HotelDashboard2
            }
          ></iframe>
        </div>
      </div>
    </div>
  );
}

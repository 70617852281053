export const API_ENDPOINT_ZA_TABLE_GET_TABLE_DATA_REF = `${process.env.REACT_APP_API_URL}/agtable/za/search`;
export const API_ENDPOINT_ZA_TABLE_GET_SCHEMA_LIST = `${process.env.REACT_APP_API_URL}/agtable/schemas/list`;
export const API_ENDPOINT_ZA_TABLE_GET_SCHEMA_DETAILS = `${process.env.REACT_APP_API_URL}/agtable/schema`;
export const API_ENDPOINT_ZA_TABLE_UPDATE_SCHEMA = `${process.env.REACT_APP_API_URL}/agtable/schema/update`;
export const API_ENDPOINT_ZA_TABLE_CREATE_SCHEMA = `${process.env.REACT_APP_API_URL}/agtable/schema/upload`;
export const API_ENDPOINT_FETCH_DB_TABLE_PREVIEW = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/preview`;
export const API_ENDPOINT_ZA_TABLE_DELETE_SCHEMA = `${process.env.REACT_APP_API_URL}/agtable/schema/`;
export const API_ENDPOINT_SAVE_TABLE_CONFIG_VIEW = `${process.env.REACT_APP_API_URL}/agtable/serverview/save`;
export const API_ENDPOINT_FETCH_CRM_MODULES = `${process.env.REACT_APP_API_URL}/finalytics/za/modules/list`;
export const API_ENDPOINT_FETCH_CRM_MODULE_FIELDS = `${process.env.REACT_APP_API_URL}/finalytics/za/modules/`;
export const API_ENDPOINT_FETCH_TABLE_DEFAULT_SERVER_PAYLOAD = `${process.env.REACT_APP_API_URL}/agtable/serverview/payload`;
export const API_ENDPOINT_CRM_SAVE_MAPPING_CONFIG = `${process.env.REACT_APP_API_URL}/finalytics/za/config/save`;
export const API_ENDPOINT_STORAGE_UPLOAD_OBJECT_CREATE_MULTIPART = `${process.env.REACT_APP_API_URL}/storage/objects/create-multipart-upload`;
export const API_ENDPOINT_STORAGE_UPLOAD_OBJECT_UPLOAD_PART = `${process.env.REACT_APP_API_URL}/storage/objects/upload/part`;
export const API_ENDPOINT_STORAGE_UPLOAD_OBJECT_COMPLETE_UPLOAD = `${process.env.REACT_APP_API_URL}/storage/objects/upload/part/complete`;
export const API_ENDPOINT_STORAGE_OBJECTS_LOGS = `${process.env.REACT_APP_API_URL}/storage/colum_mapping/logs`;
export const API_ENDPOINT_FETCH_S3_FILE_DATA = `${process.env.REACT_APP_API_URL}/storage/objects/read/data`;
export const API_ENDPOINT_FETCH_COLUMN_MAPPING_FOR_DESTINATION = `${process.env.REACT_APP_API_URL}/storage/columnmapper/list`;
export const API_ENDPOINT_FETCH_DB_TABLE_PREVIEW_PG = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/previewpg`;
export const API_ENDPOINT_FETCH_TABLE_DATA_FOR_UPLOADED_FILE = `${process.env.REACT_APP_API_URL}/storage/objects/`;

import React, { useEffect, useState } from "react";

import { message, theme } from "antd";
import { Outlet, useNavigate } from "react-router";
import "./MasterLayout.scss";

import PrimaryNav from "../PrimaryNav";
import { useRecoilState } from "recoil";
import { uploadInfo, userInfo } from "app/config/States/users";
import {
  API_ENDPOINT_GET_TABLE_SCHEMA,
  API_ENDPOINT_GET_USER_INFO,
} from "app/scenes/Auth/auth.constants";
import { apiGet } from "app/services/apiServices";
import Loader from "../Loader";
import AppLoader from "../AppLoader";
import { tableSchema } from "app/config/States/agtable";
import GlobalUploader from "../GlobalUploader";

const MasterLayout = (props: any) => {
  const [expandedLayout, setExpandedLayout] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [tableSchemaInfo, setTableSchema] = useRecoilState<any>(tableSchema);
  const [uploaderInfo, setUploadInfo] = useRecoilState<any>(uploadInfo);

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const fetchAllTableSchema = async () => {
    const response = await apiGet(API_ENDPOINT_GET_TABLE_SCHEMA);
    if (response.status) {
      setTableSchema(response.data);
    }
  };

  useEffect(() => {
    fetchUserInfo();
    fetchAllTableSchema();
  }, []);

  const fetchUserInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      let mmtWorkspace = (response.data.workspaces || []).find(
        (item: any) => item.name === "MMT Admin"
      );
      console.log("mmt workspace", mmtWorkspace);
      setUserDetails({
        ...response.data,
        currentWorkspace: mmtWorkspace
          ? mmtWorkspace
          : response.data.workspaces[0],
        clusterId: response.data.cluster_id,
      });
      localStorage.setItem(
        "currentWorkspace",
        mmtWorkspace ? mmtWorkspace.id : response.data.workspaces[0]?.id
      );
      if (!response.data.is_admin) {
        navigate("/flights");
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <AppLoader />
    </div>
  ) : (
    <div className="MasterLayout">
      <div className="NavigationContainer">
        <PrimaryNav openMenu={() => setExpandedLayout(!expandedLayout)} />
      </div>
      {/* {userDetails.hasOnboared ? (
        <div
          className="PendingTaskContainer"
          style={{ display: expandedLayout ? "flex" : "none" }}
        >
          <OnboardingTasks />
        </div>
      ) : null} */}
      <div
        className={
          "ContentLayout maxWidthLG " +
          (expandedLayout ? " " : " CollapsedLayout")
        }
      >
        <div className="AppContent">
          <Outlet />
        </div>
      </div>
      <GlobalUploader />

      {contextHolder}
    </div>
  );
};

export default MasterLayout;
